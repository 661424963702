import React from 'react';

const CompanyAddress = () => (
  <address>
     152-162 Kemp House<br />
     City Road<br />
     London EC1V 2NX<br />
     United Kingdom
  </address>
);

export default CompanyAddress;
